<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card raised class="mx-auto">
      <ModelTitle title="Restock Serial" @close="close" />

      <v-card-text>
        <v-row class="mt-5" justify="space-between">
          <v-col>
            <v-text-field
              @focus="$event.target.select()"
              label="Serial Number"
              :loading="textLoading"
              hide-details
              outlined
              prepend-inner-icon="search"
              clearable
              dense
              rounded
              @keyup.enter="loadImeiNumber()"
              v-model="returnImeiNumber"
              ref="returnImeiNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn color="success" @click="loadImeiNumber()">Search</v-btn>
          </v-col>
        </v-row>

        <v-layout column grid-list-lg>
          <v-flex class="mt-3" v-if="found">
            <v-card dark outlined>
              <v-card-text>
                <span
                  >Last Search:
                  <span class="font-weight-medium">{{
                    returnImeiNumber
                  }}</span></span
                >
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex class="mt-3" v-if="notFound">
            <v-card dark color="red">
              <v-card-text>
                <div class="text-center">
                  <div class="font-weight-medium title">Not Found</div>
                  <div class="font-weight-medium ">{{ returnImeiNumber }}</div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex class="mt-3">
            <v-card
              color="purple"
              outlined
              shaped
              dark
              v-if="PurchaseIMEIResult"
            >
              <v-card tile color="purple darken-3">
                <v-layout class="pa-2 ml-3 mr-3">
                  <div>Purchase</div>
                  <v-spacer></v-spacer>
                  <div class="pointer" @click="viewPurchase()">View</div>
                </v-layout>
              </v-card>
              <v-card-text v-if="PurchaseIMEIResult">
                <div>
                  <span class="font-weight-medium">Invoice Date: </span>
                  <span
                    >{{
                      PurchaseIMEIResult.Purchase.date | moment("MM/DD/YYYY")
                    }}
                    |
                    {{
                      PurchaseIMEIResult.Purchase.date | moment("from", "now")
                    }}</span
                  >
                </div>
                <div>
                  <span class="font-weight-medium">Purchase By: </span>
                  <span>{{ PurchaseIMEIResult.Purchase.User.name }}</span>
                </div>
                <div>
                  <span class="font-weight-medium">Item Name: </span>
                  <span>{{ PurchaseIMEIResult.name }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex class="mt-3" v-if="SaleIMEIResult">
            <v-card color="green" outlined dark shaped>
              <v-card tile color="green darken-3">
                <v-layout class="pa-2 ml-3 mr-3" row>
                  <div>Sold</div>
                  <v-spacer></v-spacer>
                  <div @click="viewInvoice()" class="pointer">View</div>
                </v-layout>
              </v-card>
              <v-card-text>
                <div>
                  <span class="font-weight-medium">Customer Name: </span>
                  <span>{{ SaleIMEIResult.Invoice.Customer.name }}</span>
                </div>
                <div>
                  <span class="font-weight-medium">Invoice Date: </span>
                  <span
                    >{{ SaleIMEIResult.Invoice.date | moment("MM/DD/YYYY") }} |
                    {{
                      SaleIMEIResult.Invoice.date | moment("from", "now")
                    }}</span
                  >
                </div>
                <div>
                  <span class="font-weight-medium">Item Name: </span>
                  <span>{{ SaleIMEIResult.name }}</span>
                </div>
                <div>
                  <span class="font-weight-medium">Amount: </span>
                  <span>{{ SaleIMEIResult.price | currency }}</span>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="restock">Restock</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import invoiceService from "@/modules/Invoice/service";
import purchaseService from "@/modules/Inventory/Purchase/service";
import { mapGetters, mapActions } from "vuex";
import Hashids from "hashids";
const hashids = new Hashids();

export default {
  data() {
    return {
      notFound: false,
      found: false,
      textLoading: false,
      dialog: false,
      SaleIMEIResult: null,
      PurchaseIMEIResult: null,
      returnImeiNumber: null,
    };
  },
  watch: {
    found(val) {
      if (val) {
        this.notFound = false;
      }
    },
    openRestockSerial(val) {
      this.dialog = val;
      if (val) {
        setTimeout(() => {
          this.$refs.returnImeiNumber.focus();
        }, 100);
      }
    },
  },
  methods: {
    ...mapActions("layout", ["setOpenRestockSerial"]),
    close() {
      this.openRestockSerial = false;
      this.returnImeiNumber = null;
      this.PurchaseIMEIResult = null;
      this.SaleIMEIResult = null
    },
    async restock() {
      await purchaseService
        .restock({ imeiNumber: this.returnImeiNumber })
        .then(() => {
          this.$swal("Restock", "Item was restock", "success").then(() => {
            this.close()
          });
        });
    },
    viewInvoice() {
      const hashId = hashids.encode(this.SaleIMEIResult.Invoice.id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPurchase() {
      const routeData = this.$router.resolve({
        path: `/global/purchase/${this.PurchaseIMEIResult.id}/view`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    async loadImeiNumber() {
      this.textLoading = true;
      await invoiceService
        .last({ imeiNumber: this.returnImeiNumber })
        .then((result) => {
          this.SaleIMEIResult = result.data[0];
          if (this.SaleIMEIResult) this.found = true;
        });
      await purchaseService
        .search({ imeiNumber: this.returnImeiNumber })
        .then((response) => {
          const result = response.data;
          this.PurchaseIMEIResult = result;
          if (this.PurchaseIMEIResult) this.found = true;
        });
      this.textLoading = false;
      if (!this.found) {
        this.notFound = true;
        // this.$swal("Not Found", `${this.returnImeiNumber} Not Found.`, "error");
      }
    },
  },
  computed: {
    ...mapGetters("layout", ["getOpenRestockSerial"]),
    openRestockSerial: {
      get: function() {
        return this.getOpenRestockSerial;
      },
      set: function(val) {
        this.setOpenRestockSerial(val);
      },
    },
  },
};
</script>

<style scoped></style>
